/* // you must include each plugins' css */
/* // paths prefixed with ~ signify node_modules */
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* date picker width custom hack */
.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

/* full calendar customizations */
.fc-toolbar h2 {
  font-size: 1.2em;
  margin: 0;
}

.fc-button {
  line-height: 1.2;
}

.fc-button .fc-icon {
  font-size: 1.2em;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.fc-event-container {
  width: 90% !important;
}

/* fontawesome customizations */
.sqdl-icon:hover {
  color: royalblue;
}
