body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.myheader {
display: flex;
position: relative;
float: top;
}

.SplitPane {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.SplitPane-left {
  left: 0;
  border: 1;
}

.SplitPane-right {
  right: 0;
  background-color: red;
}
/* // you must include each plugins' css */
/* // paths prefixed with ~ signify node_modules */


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* date picker width custom hack */
.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

/* full calendar customizations */
.fc-toolbar h2 {
  font-size: 1.2em;
  margin: 0;
}

.fc-button {
  line-height: 1.2;
}

.fc-button .fc-icon {
  font-size: 1.2em;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.fc-event-container {
  width: 90% !important;
}

/* fontawesome customizations */
.sqdl-icon:hover {
  color: royalblue;
}

